<template>
  <div class="plan">

    <el-card class="margin-t">
      <div slot="header" class="flex flex-between flex-v-center">

        <el-page-header :title="$t('返回')" v-if="$route.query.from == 'mine'" @back="goBack" :content="$t('今日计划')">
        </el-page-header>

        <h3 v-else>{{ $t('今日计划') }}</h3>


        <div class="flex flex-v-center">
          <el-date-picker v-if="!$store.state.reload" :clearable="false" @change="query" value-format="yyyy-MM-dd" v-model="date" type="date"
            style="width:160px" :placeholder="$t('选择日期')">
          </el-date-picker>

          <el-button class="margin-l" type="primary" icon="el-icon-edit" @click="to()">{{ $t('修改计划') }}</el-button>
        </div>


      </div>



      <el-card shadow="never" style="border:none;position: relative;">
        <div slot="header">
          <img v-if="tickOff" src="/web/image/tick_off.png"
            style="position: absolute;z-index: 1000;top:10px;left:200px; height: 100px;width: 100px;" />
          <el-row>
            <el-col :span="6" class="flex flex-h-center margin-lt">
              <div>
                <div class="center">
                  <span class="lt bold">{{ finish_quantity }}</span><span class="margin-l">{{ $t('题') }}</span>
                </div>
                <h3 class="lt margin-t">{{ $t('今日已完成') }}</h3>
              </div>
            </el-col>
            <el-col :span="6" class="flex flex-h-center  margin-lt">
              <div>
                <div class="center">
                  <span class="lt margin-x bold">{{ quantity }}</span><span>{{ $t('题') }}</span>
                </div>
                <h3 class="lt margin-l margin-t">{{ $t('今日总题数') }}</h3>
              </div>
            </el-col>

            <el-col :span="6" class="flex flex-h-center margin-lt">
              <div>
                <div class="center">
                  <span class="lt bold">{{ mine_count }}</span><span class="margin-l">{{ $t('词') }}</span>
                </div>
                <h3 class="lt margin-t">{{ $t('今日新加单词') }}</h3>
              </div>
            </el-col>
            <el-col :span="6" class="flex flex-h-center  margin-lt">
              <div>
                <div class="center">
                  <span class="lt margin-x bold">{{ record_count }}</span><span>{{ $t('词') }}</span>
                </div>
                <h3 class="lt margin-l margin-t">{{ $t('今日已背单词') }}</h3>
              </div>
            </el-col>
          </el-row>
        </div>
        <el-table :show-header="false" :data="tableData" style="width: 100%">
          <el-table-column class="block">
            <template slot-scope="scope">
              <div class="flex flex-between pointer flex-v-center pointer">
                <div class="flex flex-v-center flex1">
                  <el-image style="width:80px;height:80px;" :src="($host + scope.row.stype.logo)"></el-image>
                  <div class="margin-x flex1">
                    <h3>{{ scope.row.stype.title }} <el-tag size="small" class="margin-x" type="primary"
                        v-if="getType(scope.row.type)">{{ $t(getType(scope.row.type)) }}</el-tag></h3>

                    <div class="margin-ts st">
                      {{ $t('已完成') }} {{ scope.row.finish_quantity }}/{{ scope.row.quantity }}
                    </div>

                    <div style="margin-left: -15px;">
                      <el-progress :show-text="false"
                        :color="scope.row.finish_quantity >= scope.row.quantity ? 'green' : ''" class="margin-l"
                        :percentage="(scope.row.finish_quantity >= scope.row.quantity ? 100 : (scope.row.finish_quantity / scope.row.quantity) * 100)"></el-progress>
                    </div>
                  </div>
                </div>

                <el-button v-if="scope.row.finish_quantity >= scope.row.quantity" type="success" size="small" round>{{
                  $t('已完成') }}<i class="el-icon-check"></i></el-button>

                <el-button v-else-if="(scope.row.finish_quantity < scope.row.quantity && flag < 0)" class="margin-l"
                  type="danger" size="small" round>{{ $t('未完成') }}</el-button>

                <el-button v-else-if="(flag == 0)" class="margin-l" @click="to1(scope.row)" type="primary" size="small"
                  round>{{ $t('去完成') }}<i class="el-icon-right"></i></el-button>
              </div>


            </template>
          </el-table-column>
        </el-table>
      </el-card>

    </el-card>
  </div>
</template>
<script>
import moment from 'moment';
export default {
  data() {
    return {
      today: "",
      date: "",
      finish_quantity: 0,
      quantity: 0,
      tableData: [],
      flag: 0,
      mine_count: 0,
      record_count: 0,
      tickOff: false
    };
  },




  created() {
    this.today = moment().format("YYYY-MM-DD");
    this.date = moment().format("YYYY-MM-DD");
    this.query();
  },

  methods: {
    goBack() {
      this.$router.back();
    },
    getType(type) {
      if (type == 'is_gmat') {
        return "机经";
      } else if (type == 'is_new') {
        return "新题";
      } else if (type == 'is_forecast') {
        return "预测";
      } else if (type == 'is_mock') {
        return "模考";
      } else {
        return "";
      }
    },
    query() {
      this.finish_quantity = 0;
      this.quantity = 0;
      this.flag = 0;

      this.flag = moment(this.date).diff(moment(this.today));

      this.$http.post("/api/today/plans", { date: this.date }).then((data) => {
        if (data) {

          let arr1 = [];
          let arr2 = [];

          data.forEach(item => {
            this.quantity += item.quantity;
            this.finish_quantity += item.finish_quantity;
            if (item.finish_quantity >= item.quantity) {
              arr2.push(item);
            } else {
              arr1.push(item);
            }
          });
          if (arr1.length == 0 && arr2.length > 0) this.tickOff = true;
          this.tableData = arr1.concat(arr2);
        }
      })

      this.$http.post("/api/today/words", { date: this.date }).then((data) => {
        if (data) {
          this.mine_count = data.mine_count;
          this.record_count = data.record_count;
        }
      })
    },
    to() {
      this.$router.push("/main/plan/add")
    },
    to1(info) {
      if (info.type) {
        this.$router.push("/main/pte?tag=" + info.tag + "&type=" + info.type + '&back=1');
      } else {
        this.$router.push("/main/pte?tag=" + info.tag + "&back=1");
      }

    }
  },
};
</script>

<style scoped>
.plan>>>.el-button--success,
.plan>>>.el-progress.is-success .el-progress-bar__inner {
  background-color: green !important;
  border-color: green !important;
}
</style>
